<template>
  <div class="deal-info">
    <b-btn
      :disabled="disabled"
      @click="changeShowReminderFlag"
      variant="primary"
      >?</b-btn
    >
    <template v-if="showReminderFlag">
      <div
        class="deal-info__item"
        v-for="(info, index) in statusPaymentList"
        :key="index"
      >
        <div class="deal-info__badge" :style="{ background: info.color }" />
        <div>{{ info.text }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import ReestrApi from "@/services/api/ReestrApi";

export default {
  mounted() {
    this.fetchStatusPayment();
  },
  data() {
    return {
      showReminderFlag: false,
      statusPaymentList: [],
    };
  },
  methods: {
    changeShowReminderFlag() {
      this.showReminderFlag = !this.showReminderFlag;
    },
    fetchStatusPayment() {
      ReestrApi.getStatusPaymentList()
        .then((res) => {
          this.statusPaymentList = res;
        })
        .catch(console.error);
    },
  },
};
</script>

<style lang="stylus">
@require '~@/assets/stylus/vars/variables';
@require '~@/assets/stylus/mixins/mixins';
/* Стили для кнопок */
.deal-info button {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #007bff; /* Мягко-голубой цвет для кнопок */
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 5px 13px;
  cursor: pointer;
}

/* Стили для кнопок при наведении */
.deal-info button:hover {
  background-color: #0056b3; /* Изменение цвета при наведении */
}

.deal-info
  position relative
  padding-left 17px
  &__item
    flexy()
  &__badge
    border 1px solid black
    width 50px
    height 16px
    margin-right 10px
</style>
