<template>
  <div class="custom-table">
    <div class="custom-table__actions"></div>
    <div class="custom-table__filter">
      <table-filter-client v-model="model" :schema="schema"/>
      <div class="custom-table__filter_btns"></div>
    </div>
    <b-table
        hover
        striped
        class="custom-table__table"
        selectable
        select-mode="multi"
        responsive
        :fields="headers"
        :items="items"
        :busy="loading"
        ref="selectableTable"
        bordered
        :per-page="filters.limit"
        :tbody-tr-attr="rowColor"
        @row-clicked="toogleRow"
        sticky-header
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Загрузка...</strong>
        </div>
      </template>

      <template #head(selected)="">
        <b-checkbox @input="toogleAll"/>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <b-checkbox :checked="rowSelected" disabled/>
      </template>

      <template #cell(invoice_id)="{ value }">
        <a :href="gen_link_invoice(value)">{{ value }}</a>
      </template>

      <template #cell(index)="{ index }">
        {{ index + 1 }}
      </template>

      <template #cell(list_uslug)="{ value, item }">
        <b-list-group>
          <b-list-group-item
              v-for="uslug in value"
              :key="getNameUslug(uslug.name, item.id)"
          >
            {{ uslug.name }} - {{ format_currency(item, uslug.price) }} X
            {{ uslug.quantity }}
          </b-list-group-item>
        </b-list-group>
      </template>

      <template #cell(company_own)="{ value }">
        {{ value.title }}
      </template>

      <template #cell(company)="{ value }">
        {{ value.title }}
      </template>

      <template
          v-slot:[`cell(${name})`]="{ value, field, item }"
          v-for="name in nameFiles"
      >
        <div class="btn-group" :key="genNameOpen(name)">
          <b-button
              @click="openLastFile(value)"
              class="reestr__btn_badge"
              size="sm"
              variant="outline-dark"
          >
            <span>посмотреть</span>
            <b-badge
                @click.stop="showModal(field.label, value)"
                class="reestr__badge"
                variant="info"
            >{{ value.length }}
            </b-badge>
          </b-button>
        </div>
      </template>

      <template #cell(real_upload_at)="{ item }">
        <b>
          {{ item.real_upload_at }}
        </b>
      </template>

      <template #cell(defect)="{ item }">
        <div v-if="item.defect">
          <b>Да</b>
        </div>
        <div v-else>
          <b>Нет</b>
        </div>
      </template>

      <template #cell(one_c)="{ item }">
        <div v-if="item.one_c">
          <b>Да</b>
        </div>
        <div v-else>
          <b>Нет</b>
        </div>

      </template>

      <template #cell(close)="{ item }">
        <div v-if="item.close">
          <b>Да</b>
        </div>
        <div v-else>
          <b>Нет</b>
        </div>
      </template>
    </b-table>
    <table-paginate
        v-model="page"
        :totalItems="totalItems"
        @changeLimit="limit = $event"
    />
    <files-modal
        :title="modalFiles.title"
        :files="modalFiles.files"
        v-model="modalFiles.state"
    />
  </div>
</template>
<style scoped>
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
<script>
import Vue from "vue";
import {headers} from "./constants/tableHeaders";

import FilesModal from "@/components/modals/FilesModal.vue";
import PaginateMixin from "@/mixins/PaginateMixin";
import TableSelectMixin from "@/mixins/TableSelectMixin";
import ClientReestrApi from "@/services/api/ClientReestrApi";
import {model, schema} from "./constants/filter.ts";
import nameFiles from "./constants/nameFiles";
import FileAction from "@/helpers/FileAction";
import AccountCloseModal from "@/components/modals/AccountCloseModal.vue";

export default Vue.extend({
  name: "PageClients",
  // eslint-disable-next-line vue/no-unused-components
  components: {FilesModal, AccountCloseModal},
  mixins: [PaginateMixin, TableSelectMixin],
  data() {
    return {
      loading: false,
      headers,
      items: [],
      schema,
      model,
      nameFiles,
      modalFiles: {
        state: false,
        files: [],
        title: "",
      },
    };
  },

  methods: {
    async fetch() {
      this.loading = true;
      const search = {...this.model, ...this.model.date};
      delete search.date;

      const params = {...search, ...this.filters};
      ClientReestrApi.clientSearch(params)
          .then((res) => {
            this.totalItems = res.size;
            this.items = res.res;
            this.loading = false;
            setTimeout(this.initTable.bind(this), 100);
          })
          .catch(console.error);
    },

    async fetch_not_reload() {
      this.loading = false;
      const search = {...this.model, ...this.model.date};
      delete search.date;
      const params = {...search, ...this.filters};
      ClientReestrApi.clientSearch(params)
          .then((res) => {
            this.totalItems = res.size;
            this.items = res.res;
            this.loading = false;
            setTimeout(this.initTable.bind(this), 100);
          })
          .catch(console.error);
    },
    gen_link_invoice(id) {
      return "https://b24.datrans.ru/crm/type/31/details/" + id + "/";
    },
    format_currency(fmt_str, price) {
      if (typeof fmt_str.currency == "undefined") return price;
      else return fmt_str.currency.replace("#", price);
    },
    rowColor(item, type) {
      if (!item || type !== "row") return;
      // || type !== "row"
      return {style: `background: ${item.state_invoice}`};
    },
    list_uslug(list_uslug) {
      if (list_uslug.length > 0) {
        return list_uslug.join("<br>");
      }
      return "";
    },

    toggle_defect(id) {
      ClientReestrApi.setStatusDefect({id: id})
          .then((res) => {
            this.fetch_not_reload();
            this.makeNotification(
                "Действие",
                "Установлен статус 'дефектный'",
                "success"
            );
          })
          .catch(console.error);
    },
    genName(name) {
      return name + "-add-file";
    },
    genNameInput(name, id) {
      return name + "-add-file-input_" + id;
    },
    getNameUslug(name, id) {
      return name + "-uslug-" + id;
    },
    openLastFile(files) {
      FileAction.open(files[files.length - 1].path);
    },
    showModal(label, files) {
      this.modalFiles.state = true;
      this.modalFiles.files = files;
      this.modalFiles.title = label;
    },

    genNameOpen(item) {
      return "button-open-file-" + item;
    },
    colorButtonDefect(flag) {
      if (flag == 0) {
        return "primary";
      } else {
        return "none";
      }
    },
  },

  async mounted() {
    this.fetch();
  },

  watch: {
    filters() {
      this.fetch_not_reload();
    },
    model: {
      handler() {
        this.fetch_not_reload();
      },
      deep: true,
    },
  },
});
</script>

<style lang="stylus">
@require '~@/assets/stylus/vars/variables';
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/style/table.styl';
</style>
