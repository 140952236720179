import Api from "@/services/api/BaseApiService";
// import { IList } from "@/models/types";
//
// import ClientInvoice, { ClientInvoiceConfig } from "@/models/ClientInvoice";
//
// import Company, { CompanyConfig } from "@/models/Company";

const invoices_name = "/invoices";

export default abstract class InvoiceApi extends Api {
    static check(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService
                .post(`${invoices_name}/change_check`, { params })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    }
    
    static search(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService
                .get(`${invoices_name}/search`, { params })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    }
}
