export const schema = [
    {
        component: "company-select-client",
        model: "company",
    },
];

export const model = {
    company: null,
};
