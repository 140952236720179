import Vue from "vue";

import VueRouter, {RouteConfig} from "vue-router";
import BaseLayout from "../layouts/BaseLayout.vue";

import PageReestr from "../views/Reestr/PageReestr.vue";
import PageReestrView from "../views/Reestr/PageReestrView.vue";
import PageClients from "../views/Clients/PageClients.vue";
import NotFound from "../views/NotFound.vue";
import PageDeveloping from "../views/PageDeveloping.vue";
import Login from "@/views/Login.vue";
import store from "@/store";
import PageClientsView from "@/views/Clients/PageClientsView.vue";
import pageInvoices from "@/views/Invoices/PageInvoices.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: BaseLayout,
        redirect: {name: "reestr"},
        meta: {requiresAuth: true},
        children: [
            {
                path: "/reestr",
                name: "reestr",
                component: PageReestr,
            },
            {
                path: "developing",
                name: "developing",
                component: PageDeveloping,
            },
            {
                path: "http://perevoz.datrans.ru:8443/",
                beforeEnter() {
                    window.location.href = "http://perevoz.datrans.ru:8443";
                },
                name: "contract1",
                component: PageDeveloping,
            },
            {
                path: "http://perevoz.datrans.ru:8443/client",
                beforeEnter() {
                    window.location.href = "http://perevoz.datrans.ru:8443/client";
                },
                name: "contract2",
                component: PageDeveloping,
            },
            {
                path: "http://perevoz.datrans.ru/#main-tab",
                beforeEnter() {
                    window.location.href = "http://perevoz.datrans.ru/#main-tab";
                },
                name: "scans",
                component: PageDeveloping,
            },
            {
                path: "http://perevoz.datrans.ru/#tab-original",
                beforeEnter() {
                    window.location.href = "http://perevoz.datrans.ru/#tab-original";
                },
                name: "originals",
                component: PageDeveloping,
            },
            {
                path: "http://perevoz.datrans.ru/#tab-paid",
                name: "forPayment",
                beforeEnter() {
                    window.location.href = "http://perevoz.datrans.ru/#tab-paid";
                },
                component: PageDeveloping,
            },
            {
                path: "/clients",
                name: "clients",
                component: PageClients,
            },
            {
                path: "/invoices",
                name: "invoices",
                component: pageInvoices,
            },
        ],
    },
    {
        path: "/clients/view",
        name: "clients_view",
        component: PageClientsView,
        meta: {requiresAuth: true},
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: "/404",
        name: "404",
        component: NotFound,
    },
    {
        path: "*",
        redirect: "/login",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiresAuth)) {
        // Проверяем, есть ли авторизованный пользователь в состоянии (vuex)
        if (localStorage.user == 'admin') {
            next();
        } else if (localStorage.user == "view") {
            if ( to.path == '/clients/view') {
                next();
            } else {
                next('/clients/view');
            }
        } else {
            next('/login');
        }
    } else {
        next();
        // next('/login');

    }
});


export default router;
