import { ActionTree, Module, MutationTree, GetterTree } from "vuex";
import router from "@/router";

const state = (): any => ({
  token: "",
  refreshToken: "",
  loading: false,
  email: "",
  user: null,
});

const getters: GetterTree<any, any> = {};

const mutations: MutationTree<any> = {};

const actions: ActionTree<any, any> = {
  // login({ commit }, user) {
  //   // Тут можно добавить проверку на наличие пользователя в базе данных или другие проверки авторизации
  //   if (user.username === 'admin' && user.password === 'password') {
  //     commit('setUser', user);
  //     router.push('/home');
  //   } else {
  //     // Если авторизация не удалась, можно вывести сообщение об ошибке
  //     alert('Неверное имя пользователя или пароль');
  //   }
  // },
  // logout({ commit }) {
  //   commit('setUser', null);
  //   router.push('/login');
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<any, any>;
