var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-period-select"},[_c('div',{staticClass:"base-period-select__datapicker"},[_c('b-form-datepicker',{staticClass:"reestr__datepicker",attrs:{"disabled":_vm.disabled,"date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },"locale":"ru","placeholder":"От"},model:{value:(_vm.inputVal.date_from),callback:function ($$v) {_vm.$set(_vm.inputVal, "date_from", $$v)},expression:"inputVal.date_from"}})],1),_c('span',{staticClass:"base-period-select__separator"},[_vm._v("—")]),_c('div',{staticClass:"base-period-select__datapicker"},[_c('b-form-datepicker',{staticClass:"reestr__datepicker",attrs:{"disabled":_vm.disabled,"date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },"locale":"ru","placeholder":"До"},model:{value:(_vm.inputVal.date_to),callback:function ($$v) {_vm.$set(_vm.inputVal, "date_to", $$v)},expression:"inputVal.date_to"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }