<template>
  <div class="custom-table">
    <div class="custom-table__filter">
      <div style="display:flex">
        <b-input v-model="filters.text" placeholder="Поиск" style="margin-bottom:30px;width:700px"/>
        <b-btn
            :disabled="disabled"
            @click="fetch"
            variant="primary"
            style="margin:0; height: 40px;width:120px"
        >Найти</b-btn>
        <b-btn
            type="reset"
            @click="filters = {}; color_key = 'Выберите статус'"
            class="filter__btn"
            variant="danger"
            style="margin:0; height: 40px;width:120px"
        >Сбросить</b-btn>
      </div>

      <div style="display:flex">
        <b-input v-model="filters.deal_id" type="number" placeholder="ID сделки" />
        <b-input v-model="filters.invoice_id" type="number" placeholder="ID счета" />
        <b-input v-model="filters.assigned_full_name" placeholder="ФИО МК" />
        <b-input v-model="filters.assigned_email" placeholder="Эл. почта МК" />
        <b-select
            :disabled="loading || color_options.length == 0"
            label-field="Статус"
            class="company-select"
            size="lg"
            v-model="color_key"
            :options="Object.keys(color_options)"
        />
        <b-form-select
            :disabled="loading || company_options.length == 0"
            label-field="Компания"
            class="company-select"
            size="lg"
            v-model="filters.company"
            :options="company_options"
        />
<!--        <company-select-->
<!--            v-model="filters.company"-->
<!--        >-->

<!--        </company-select>-->
      </div>
    </div>
    <b-table
        hover
        striped
        class="custom-table__table"
        selectable
        select-mode="multi"
        responsive
        :fields="headers"
        :items="items"
        :busy="loading"
        ref="selectableTable"
        bordered
        :per-page="filters.limit"
        :tbody-tr-attr="rowColor"
        sticky-header
    >
      <template #thead-top="{ headers }">
        <tr>
          <th v-for="column in headers" :key="column.key" :class="column.key">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ column.label }}</span>
              <!-- Добавление фильтров в каждую ячейку заголовка -->
              <b-input v-model="filters[column.key]" placeholder="Фильтр"></b-input>
            </div>
          </th>
        </tr>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Загрузка...</strong>
        </div>
      </template>

      <template #cell(invoice_id)="{ value }">
        <a :href="gen_link_invoice(value)">{{ value }}</a>
      </template>

      <template #cell(deal_id)="{ value }">
        <a :href="gen_link_deal(value)">{{ value }}</a>
      </template>

      <template #cell(index)="{ index }">
        {{ index + 1 }}
      </template>

      <template #cell(check)="{ item }">
        <b-button
            :disabled="false"
            :active="item.color != '#00FF00'"
            @click="check(item.deal_id)"
            :variant="colorButtonDefect(item.color == '#00FF00')"
        >
          Проверить
        </b-button>
      </template>

      <template #cell(expose)="{ item }">
        <b-button
            :disabled="false"
            :active="true"
            @click="expose(item.assigned_id, item.deal_id)"
            :variant="colorButtonDefect(item.color == '#00FF00')"
        >
          Выставить
        </b-button>
      </template>

      <template #cell(send_invoice)="{ item }">
        <b-button
            :disabled="false"

            :active="true"
            @click="send_invoice(item.assigned_id, item.deal_id)"
            :variant="colorButtonDefect(item.color == '#00FF00')"
        >
          Отправить
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import Vue from "vue";
import { headers } from "./constants/tableHeaders";
import PaginateMixin from "@/mixins/PaginateMixin";
import TableSelectMixin from "@/mixins/TableSelectMixin";

import InvoiceApi from "@/services/api/InvoiceApi";
import {model, schema} from "@/views/Invoices/constants/filter";
import ClientReestrApi from "@/services/api/ClientReestrApi";

export default Vue.extend({
  name: "PageInvoices",
  mixins: [PaginateMixin, TableSelectMixin],

  data() {
    return {
      color_options: {
        'Выберите статус': '',
        'Проверенные': '#00FF00',
        'Нет счета': '#F2F2F2',
        "Счет 'Новый'": '#0000FF'
      },
      company_options: [],
      color_key: 'Выберите статус',
      loading: false,
      headers,
      items: [],
      schema,
      model,
      filters: {},
    };
  },

  methods: {

    async fetch() {
      this.loading = true;
      const search = { ...this.model, ...this.model.date };
      delete search.date;
      this.filters.color = this.color_options[this.color_key]

      const params = { ...search, ...this.filters };
      InvoiceApi.search(params)
          .then((res) => {
            this.items = res.invoices;
            this.totalItems = res.size;
            this.loading = false;
          })
          .catch(console.error);
    },
    async check(id) {
      InvoiceApi.check({deal_id: id})
      this.fetch();
    },

    async fetch_not_reload() {
      this.loading = false;
      const search = { ...this.model, ...this.model.date };
      delete search.date;
      this.filters.color = this.color_options[this.color_key]

      const params = { ...search, ...this.filters };
      InvoiceApi.search(params)
          .then((res) => {
            this.items = res.invoices;
            this.totalItems = res.size;
            this.loading = false;
          })
          .catch(console.error);
    },
    async expose(user_id, deal_id) {
      const url = "https://b24.datrans.ru/rest/9229/1u6ekohkds4c4vg8/im.notify.system.add.json?USER_ID=" + user_id;
      const data = {
        MESSAGE: `По сделке ${this.gen_link_deal(deal_id)} отсутствует счет. Выстави и отправь клиенту!`
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Указываем, что отправляем JSON
          },
          body: JSON.stringify(data), // Преобразуем данные в JSON-строку
        });

        if (!response.ok) {
          throw new Error("Ошибка при отправке письма");
        }
      } catch (error) {
        console.error("Ошибка:", error);
      }
    },
    async send_invoice(user_id, deal_id) {
      console.log('ftyukghj')
      const url = "https://b24.datrans.ru/rest/9229/1u6ekohkds4c4vg8/im.notify.system.add.json?USER_ID=" + user_id;
      const data = {
        MESSAGE: `По сделке ${this.gen_link_deal(deal_id)} счет не отправлен клиенту! Не забудь отправить!`
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Указываем, что отправляем JSON
          },
          body: JSON.stringify(data), // Преобразуем данные в JSON-строку
        });

        if (!response.ok) {
          throw new Error("Ошибка при отправке письма");
        }
      } catch (error) {
        console.error("Ошибка:", error);
      }
    },
    gen_link_invoice(id) {
      return "https://b24.datrans.ru/crm/type/31/details/" + id + "/";
    },
    gen_link_deal(id) {
      return "https://b24.datrans.ru/crm/deal/details/" + id + "/";
    },
    format_argeement(value) {
      if (value) {
        return "Да";
      } else {
        return "Нет";
      }
    },
    rowColor(item, type) {
      if (!item || type !== "row") return;
      // || type !== "row"
      return { style: `background: ${item.color}` };
    },

    colorButtonDefect(flag) {
      if (flag == 0) {
        return "primary";
      } else {
        return "none";
      }
    },

  },

  async mounted() {
    ClientReestrApi.getCompanies()
        .then((items) => {
          this.company_options = items.map((item) => {
            return { value: item.id, text: item.OwnCompany };
          });
        })
        .catch((e) => {
          throw e;
        })
    this.fetch();
  },

  watch: {
    filters() {
      this.fetch_not_reload();
    },
    model: {
      handler() {
        this.fetch_not_reload();
      },
      deep: true,
    },
  },
});
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
      0,
      0,
      0,
      0.5
  ); /* Четвертое значение (0.5) задает прозрачность */
  z-index: 999; /* Поднимаем слой над остальным содержимым */
}

.overlay-button {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон для затемнения */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Установите очень высокий уровень z-index */
}

.overlay-button button {
  /* Стилизация кнопки */
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}
.close-button {
  margin: 10px;
}
</style>
