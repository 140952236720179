import { TableHeaders } from "@/models/types";

export const headers: TableHeaders[] = [
    { key: "index", label: "№ записи" },
    { key: "deal_id", label: "Сделка" },
    { key: "invoice_id", label: "Счет" },
    { key: "assigned_full_name", label: "ФИО МК" },
    { key: "my_company_title", label: "Наша компания" },
    { key: "assigned_email", label: "Эл. почта МК" },
    { key: "expose", label: "Выставить" },
    { key: "send_invoice", label: "Отправить счет" },
    { key: "check", label: "Проверено" },
];
