import { render, staticRenderFns } from "./BaseAddableFile.vue?vue&type=template&id=0101ab78"
import script from "./BaseAddableFile.vue?vue&type=script&lang=js"
export * from "./BaseAddableFile.vue?vue&type=script&lang=js"
import style0 from "./BaseAddableFile.vue?vue&type=style&index=0&id=0101ab78&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports