<template>
  <div class="login">

    <div class="login-form">
      <div class="login-header">
        <h1>Супер-Реестр</h1>
      </div>

      <input type="text" :disabled="disabled" v-model="username" placeholder="Логин" />
      <br>
      <input type="password" :disabled="disabled" v-model="password" placeholder="Пароль"/>
      <br>
      <input type="button" value="Вход" class="login-button" :disabled="disabled" @click="login"/>

      <h6 class="no-access">{{error}}</h6>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      username: '',
      password: ''
    };
  },
  methods: {
    login() {
      if (this.username === 'admin' && this.password === 'reestrfront') {
        this.error = '';
        localStorage.user = "admin";
        this.$router.push('/reestr');
      } else if (this.username === 'view' && this.password === 'view') {
        this.error = '';
        localStorage.user = "view";
        this.$router.push('/clients/view');
      } else {
        this.error = 'Неправильный логин или пароль';
      }
    }
  },
};
</script>

<style>
h1, h2, h3, h4, h5, h6, a {
  margin:0; padding:0;
}
.login {
  margin:0 auto;
  max-width:500px;
}
.login-header {
  color:#fff;
  text-align:center;
  font-size:300%;
}
/* .login-header h1 {
   text-shadow: 0px 5px 15px #000;
}*/
.login-form {
  border:.5px solid #fff;
  background:#007bff;
  border-radius:10px;
  box-shadow:0px 0px 10px #000;
}
.login-form h3 {
  text-align:left;
  margin-left:40px;
  color:#fff;
}
.login-form {

  box-sizing:border-box;
  padding-top:15px;
  padding-bottom:10%;
  margin:5% auto;
  text-align:center;
  margin-top: 300px;
}
.login input[type="text"],
.login input[type="password"] {
  max-width:400px;
  width: 80%;
  line-height:3em;
  font-family: 'Ubuntu', sans-serif;
  margin:1em 2em;
  border-radius:5px;
  border:2px solid #f2f2f2;
  outline:none;
  padding-left:10px;
}
.login-form input[type="button"] {
  height:30px;
  width:100px;
  background:#fff;
  border:1px solid #f2f2f2;
  border-radius:20px;
  color: slategrey;
  text-transform:uppercase;
  font-family: 'Ubuntu', sans-serif;
  cursor:pointer;
}

.no-access {
  color:#E86850;
  margin:20px 0px 20px -57%;
  text-decoration:underline;
  cursor:pointer;
}


/*Media Querie*/
@media only screen and (min-width : 150px) and (max-width : 530px){
  .login-form h3 {
    text-align:center;
    margin:0;
  }
  .login-button {
    margin-bottom:10px;
  }
}
</style>

