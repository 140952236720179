<template>
  <div class="popup-container">
    <span class="close-button" @click="hide_close_modal">&times;</span>
    <h2 class="popup-title">Закрыть с отправкой документов</h2>
    <h1 class="popup-head">Почта</h1>
    <span v-if="!mail" class="error"
      >Пожалуйста, введите адрес электронной почты</span
    >
    <input type="email" v-model="mail" placeholder="Введите почту" required />
    <h1 class="popup-head">Тема</h1>
    <span v-if="!theme" class="error">Пожалуйста, введите тему письма</span>
    <textarea
      id="autoresize-theme"
      type="text"
      v-model="theme"
      placeholder="Введите тему письма"
      required
    ></textarea>
    <h1 class="popup-head">
      Содержание (не считая контактных данных и документов)
    </h1>
    <textarea
      id="autoresize-content"
      type="text"
      rows="4"
      v-model="content"
      placeholder="Введите содержание, без него отправятся только документы и контактная информация"
    ></textarea>
    <button @click="checkAndSend">Отправить</button>
    <button @click="hide_close_modal" style="float: right">Отмена</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mail: this.close_mail,
      theme: this.close_theme,
      content: this.close_content,
    };
  },
  props: [
    "get_close_with_doc_mail",
    "close_content",
    "close_theme",
    "close_mail",
    "close_item",
    "hide_close_modal",
  ],
  methods: {
    checkAndSend() {
      // if (this.name && this.mail) {
      this.get_close_with_doc_mail(
        this.close_item,
        this.mail,
        this.theme,
        this.content
      );
      // } else {
      //   alert('Пожалуйста, заполните все обязательные поля.');
      // }
    },
  },

  watch: {
    theme() {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector("#autoresize-theme");
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      });
    },
    content() {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector("#autoresize-content");
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      });
    },
  },
};
</script>

<style scoped>
/* Стили для попапа */
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Тень */
  border-radius: 10px;
  padding: 20px;
  z-index: 2147483647;
}

#autoresize-theme {
  resize: none; /* Отключает возможность изменения размера textarea пользователем */
  overflow: hidden; /* Скрывает любое содержимое, которое не помещается в textarea */
  width: 100%; /* Устанавливаем ширину на 100% */
}

#autoresize-content {
  resize: none; /* Отключает возможность изменения размера textarea пользователем */
  overflow: hidden; /* Скрывает любое содержимое, которое не помещается в textarea */
  width: 100%; /* Устанавливаем ширину на 100% */
}

/* Стили для текстовых полей */
.popup-container input,
.popup-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-container input:focus {
  border-width: 2px; /* Измените значение на желаемую толщину границы */
  /* Дополнительные стили при активации, например, изменение цвета */
  border-color: #80b0e5;
  outline: none; /* Убирает стандартное выделение на некоторых браузерах */
}

.popup-container textarea:focus {
  border-width: 2px; /* Измените значение на желаемую толщину границы */
  /* Дополнительные стили при активации, например, изменение цвета */
  border-color: #80b0e5;
  outline: none; /* Убирает стандартное выделение на некоторых браузерах */
}

/* Стили для кнопок */
.popup-container button {
  background-color: #007bff; /* Мягко-голубой цвет для кнопок */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

/* Стили для кнопок при наведении */
.popup-container button:hover {
  background-color: #0056b3; /* Изменение цвета при наведении */
}

/* Заголовок попапа */
.popup-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-head {
  color: #0056b3;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Закрыть кнопку в углу попапа */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
</style>
